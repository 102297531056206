const getQueryString = (name) => {

  let search = decodeURIComponent(window.location.search).replace('?', '')
  const tempArr = search !== '' ? search.split('&') : []

  tempArr.forEach((item) => {
    if (item) {
      const itemArr = item.split('=')
      search = search.replace(itemArr[0], itemArr[0].toLowerCase())
    }
  })

  const reg = new RegExp(`(^|&)${name.toLowerCase()}=([^&]*)(&|$)`)
  const result = search.match(reg)

  return result != null ? result[2] : ''
}


export {
  getQueryString
}