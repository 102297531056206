import axios from 'axios'

const bluehillApiDomain = () => {
    if (process.env.REACT_APP_STAGE === 'prod') {
        return 'https://api.bluehill.coffee';
    } else if (process.env.REACT_APP_STAGE === 'beta') {
        return 'https://api-beta.bluehill.coffee';
    } else {
        return 'https://api-beta.bluehill.coffee';
    }
};
 
const http = axios.create({
  baseURL: bluehillApiDomain(),
  timeout: 5000
})

// add request interceptors
http.interceptors.request.use((config) => config, (error) => Promise.reject(error))

// add response interceptors
http.interceptors.response.use((response) => response.data, (error) => {
  return Promise.reject(error)
})

export default http
