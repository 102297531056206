
import './index.css'

import Logo from '../../components/Logo'
import Footer from '../../components/Footer'
import StoreLocation from '../../components/StoreLocation'
import SetimatedTimeIcon from '../../assets/images/setimated-time-icon.png'


function Waiting (props) {
    return (
        <div className='waiting-warpper'>
          <Logo></Logo>
          <div className='info-wrapper'>
            <span className='order-info-title'>Thanks for your order</span>
            {
                props.waitingTime === undefined || props.waitingTime === null || props.waitingTime > 0 ?
                    waitingInfo(props.waitingTime):
                    readyInfo()
            }
            <StoreLocation storeInfo={props.storeInfo}></StoreLocation>
          </div>
          <Footer></Footer>
        </div >
    );
}

function waitingInfo(waitingTime) {
    return (
        <div className='estimated-time-wrapper'>
            <div className='estimated-time-top'>
                <img className='estimated-time-icon' src={SetimatedTimeIcon} alt="time" />
                <span className='estimated-time-title'>Estimated Time</span>
            </div>
            <div className='estimated-time-info'>
                <span className='estimated-time-value'>{waitingTime}</span>
                <span className='estimated-time-unit'>mins</span>
            </div>
        </div>
    );
}

function readyInfo() {
    return (
        <div className='estimated-time-wrapper'>
            <div className='estimated-time-top'>
                <img className='estimated-time-icon' src={SetimatedTimeIcon} alt="time" />
                <span className='estimated-time-title'>Ready to pickup</span>
            </div>
        </div>
    );
}

export default Waiting