import http from '../http'

const getOrderWaitingInfo = (orderId) => {
  return new Promise((resolve, reject) => {
    http.get('/v1/orders/' + orderId + '/waiting_info').then(resp => {
      
      const estimatedMaxWaitingTime = parseInt(resp.estimatedMaxWaitingTime)
      const estimatedMinWaitingTime = parseInt(resp.estimatedMinWaitingTime)
      if (!Number.isNaN(estimatedMaxWaitingTime)
        && !Number.isNaN(estimatedMinWaitingTime)) {
        const waitingTime = estimatedMinWaitingTime / 1000 / 60 | 0
        resolve({
          status: 'Waiting',
          waitingTime
        })
      } else {
        resolve({
          status: 'Invalid'
        })
      }
    }).catch(error => {
      if (error.response.status === 410) {
        resolve({
          status: 'Expired',
          waitingTime: 0
        })
      } else {
        resolve({
          status: 'Invalid'
        })
      }
    })
  })
}

const getStoreInfo = (storeId) => {
  return http.get('/v1/stores/' + storeId)
}

export {
  getOrderWaitingInfo,
  getStoreInfo
}