import { getQueryString } from './utils'
import { getOrderWaitingInfo, getStoreInfo } from './api'
import Waiting from './pages/Waiting'
import Expired from './pages/Expired'
import Invalid from './pages/Invalid'

import { useEffect, useState } from 'react'
function App () {
  const [orderWaitingInfo, setOrderWaitingInfo] = useState({
    status: 'Waiting',
    waitingTime: ''
  })
  const [storeInfo, setStoreInfo] = useState({})

  const [params] = useState({
    orderId: getQueryString('orderId'),
    storeId: getQueryString('storeId')
  })
  useEffect(() => {
    const loadOrderWaitingInfo = async () => {
      const waitingInfo = await getOrderWaitingInfo(params.orderId)
      setOrderWaitingInfo(waitingInfo)
    }
    const loadStoreInfo = async () => {
      const storeInfo = await getStoreInfo(params.storeId)
      setStoreInfo(storeInfo)
    }
    loadOrderWaitingInfo()
    loadStoreInfo()
  }, [params])
  return (
    <>
        <OrderInfo orderWaitingInfo={orderWaitingInfo} storeInfo={storeInfo} />
    </>
  )
}

function OrderInfo(props) {
    if (props.orderWaitingInfo.status === 'Waiting') {
        return <Waiting storeInfo={props.storeInfo} waitingTime={props.orderWaitingInfo.waitingTime} />
    } else if (props.orderWaitingInfo.status === 'Expired') {
        return <Expired storeInfo={props.storeInfo} />
    } else {
        return <Invalid />
    }
}

export default App
