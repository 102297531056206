import './index.css'

function Footer () {
  return (
    <div className='footer-wrapper'>
      <div className='fotter-line  fotter-line-left'></div>
      <div className="fotter-img"></div>
      <div className='fotter-line  fotter-line-right'></div>
    </div>

  )
}

export default Footer