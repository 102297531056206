import logoImg from '../../assets/images/logo.png'
import './index.css'

function Logo () {
  return (
    <div className='logo-wrapper'>
      <img className="logo" src={logoImg} alt="" />
    </div>

  )
}

export default Logo