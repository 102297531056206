import './index.css'
import LocationIcon from '../../assets/images/location-icon.png'

function StoreLocation (props) {
  return (
    <div className='location-wrapper'>
      {
        props.storeInfo.storeName ? (
          <div className='location-title-header'>
            <img className='location-icon' src={LocationIcon} alt="time" />
            <span className='location-label'>Pickup Location</span>
          </div>
        ) : ''
      }
      <div className='location-info-wrapper'>
        <span className='location-info'>{props.storeInfo.storeName}</span>
      </div>
    </div>
  )
}

export default StoreLocation