import './index.css'

import Logo from '../../components/Logo'
import Footer from '../../components/Footer'
import ExpiredIcon from '../../assets/images/expired-icon.png'


function Exipred () {
  return (
    <div className='expired-warpper'>
      <Logo></Logo>
      <div className='expired-main-wrapper'>
        <div className='expired-info-wrapper'>
          <img className='expired-icon' src={ExpiredIcon} alt=''></img>
          <div className='expired-label'>Requested information is</div>
          <div className='expired-label'>expired</div>
        </div>
      </div>
      <Footer></Footer>
    </div >
  )
}

export default Exipred