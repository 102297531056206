import './index.css'

import Logo from '../../components/Logo'
import Footer from '../../components/Footer'
import ExpiredIcon from '../../assets/images/expired-icon.png'
import SetimatedTimeIcon from '../../assets/images/setimated-time-icon.png'

function Invalid () {
  return (
    <div className='expired-warpper'>
      <Logo></Logo>
      <div className='expired-main-wrapper'>
        <div className='expired-info-wrapper'>
          <img className='expired-icon' src={SetimatedTimeIcon} alt="time" />
          <div className='expired-label'>Can't get waiting time</div>
          <div className='expired-label'>Please contact store staff for your order</div>
        </div>
      </div>
      <Footer></Footer>
    </div >
  )
}

export default Invalid